import { P } from 'assets/jss/material-kit-react.jsx'

const sizeChartsStyle = {
  root: {},
  sizesWrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',

  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    width: 300,
    height: 300,
    margin: 5,
    backgroundColor: 'transparent',
    border: '1px solid #2a2a2a',
    '&:hover,&:focus': {
      border: '1px solid #2a2a2a',
      boxShadow:
        '0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)',
      color: 'white',
    },
  },
  subWrapper: {
    display: 'flex',
    flex: 3,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sizeTitle: {
    ...P,
    color: '#fff',
    fontWeight: 800,
  },
  logo: {
    width: 120,
  },
}

export default sizeChartsStyle
