import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

import { graphql, Link } from 'gatsby'
import PageHeader from '../../components/PageHeader/PageHeader'
import Footer from '../../components/Footer/Footer'
import Section from '../../components/Section/Section'
import sizeChartsStyle from './SizeChartsStyle'
import RecentBlogPosts from '../../elements/RecentBlogPosts/RecentBlogPosts'
import Img from 'gatsby-image'
import HeaderProvider from '../../components/Header/HeaderProvider'
import BannerWithImg from '../../elements/BannerWithImg/BannerWithImg'
import SEO from '../../components/SEO'

const styles = {
  root: {},
}


const SizeCharts = props => {
  const { classes, data } = props
  const modified = []
  data.datoCmsSizeChart._allUrlLocales.map(item => {
    if (item.locale === 'de') {
      modified.push({ 'locale': item.locale, 'value': item.value })
    } else {
      modified.push({ 'locale': item.locale, 'value': item.value })
    }
  })
  return (
    <div className={classes.root}>
      <SEO
        seoMetaTags={data.datoCmsSizeChart.seoMetaTags.tags}
        actLang={props.pageContext.locale}
        localURLS={modified}
      />
      <HeaderProvider
        localURLS={data.datoCmsSizeChart._allUrlLocales}
        actLang={props.pageContext.locale}
        SEOtitle={data.datoCmsSizeChart.pageTitle}
        SEOdescription={data.datoCmsSizeChart.pageSubtitle}
        SEOimage={data.datoCmsSizeChart.headerImage.fluid.src}
        SEOpathname={data.datoCmsSizeChart.url}


      />

      <PageHeader
        leftHeader
        title={data.datoCmsSizeChart.pageTitle}
        subtitle={data.datoCmsSizeChart.pageSubtitle}
        image={data.datoCmsSizeChart.headerImage.fluid}

      />

      <Section paddingTop={0}>

        <div className={classes.sizesWrapper}>
          {data.allDatoCmsBrand.nodes ? data.allDatoCmsBrand.nodes.map(brand => {
            // const foo = brand.brandNameDatabase.replace(/\s/g, '').replace(/'/g, '')
            return (
              <Link
                to={props.pageContext.locale === 'de' ? 'de/groessentabellen/' + brand.url : '/sizecharts/' + brand.url}
                className={classes.wrapper}
              >
                <div className={classes.subWrapper}>
                  <Img
                    fluid={brand.image.fluid}
                    alt={brand.image.alt}
                    backgroundColor={'transparent'}
                    style={{ width: 120 }}
                  />
                </div>
                <div style={{
                  display: 'flex', flex: 1,
                  backgroundColor: '#2a2a2a',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}>

                  <h2 className={classes.sizeTitle}>{brand.brandname}</h2>
                </div>

              </Link>
            )
          }) : null}
        </div>
      </Section>
      <BannerWithImg
        title={data.datoCmsHome.bannerWithBackgroundImage[0].title}
        text={data.datoCmsHome.bannerWithBackgroundImage[0].text}
        image={data.datoCmsHome.bannerWithBackgroundImage[0].image}
        buttonTitle={data.datoCmsHome.bannerWithBackgroundImage[0].buttonTitle}
        link={data.datoCmsHome.bannerWithBackgroundImage[0].buttonLink.url}
        actLang={props.pageContext.locale}
      />
      {data.allDatoCmsArticle ?
        <RecentBlogPosts
          whitebg
          articles={data.allDatoCmsArticle.nodes}
          actLang={props.pageContext.locale}/> : null}
      <Footer
        actLang={props.pageContext.locale}
      />
    </div>
  )
}

export default withStyles(sizeChartsStyle)(SizeCharts)

export const query = graphql`
    query( $locale: String!) {

        datoCmsSizeChart( locale:{eq:$locale}){
            seoMetaTags{
                tags
            }
            _allUrlLocales{locale value}
            url
            pageTitle
            pageSubtitle
            headerImage{
                alt
                fluid(maxWidth:1800){
                    ...GatsbyDatoCmsFluid_noBase64
                }
            }
        }
        allDatoCmsArticle(
            sort:{
                fields:meta___updatedAt
                order:DESC
            }
            limit:6
            filter:{
                meta:{status:{eq:"published"}  }
                locale:{eq:$locale}
            }
        ){
            nodes{
                locale
                url
                title
                meta{
                    updatedAt(formatString: "MM-DD-YYYY")
                }
                summary
                featuredImage{
                    alt
                    fluid(maxWidth:705){
                        src
                        ...GatsbyDatoCmsFluid_noBase64
                    }
                }
            }
        }
        allDatoCmsBrand(filter:{locale:{eq:$locale}}){
            totalCount
            nodes{

                brandname
                meta{createdAt}
                subtitle
                brandNameDatabase
                url
                image{
                    alt
                    fluid(maxWidth:705){
                        src
                        ...GatsbyDatoCmsFluid_noBase64

                    }
                }
            }
        }
        datoCmsHome(locale:{eq:$locale}) {

            bannerWithBackgroundImage{
                title
                text
                image{
                    alt
                    fluid(maxWidth:1800){
                        ...GatsbyDatoCmsFluid_noBase64
                    }
                }
                buttonTitle
                buttonLink{
                    ...on DatoCmsSizeFinder{
                        url
                    }
                    ...on DatoCmsWetsuitFinder{
                        url
                    }

                }
            }

        }

    }
`